<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card>
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
				<b-row>
					<ResponseAlert ref="response" />
					<b-col sm="8" class="mb-2">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('title')}" label="Title">
							<b-form-input v-model="models.title" v-validate="'required'" data-vv-name="title">
							</b-form-input>
							<div v-if="errors.has('title')">
								<p class="text-danger">{{ errors.first('title') }}</p>
							</div>
						</b-form-group>

						<b-form-group v-bind:class="{ 'is-invalid': errors.has('description')}" label="Description">
							<Editor :value="models.description" @input="value => models.description = value"/>
							<div v-if="errors.has('description')">
								<p class="text-danger">{{ errors.first('description') }}</p>
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="4" class="mt-2">
						<b-form-group>
							<b-form-radio-group
								v-model="models.send_by"
								name="send_by"
							>
								<b-form-radio value="email">Email</b-form-radio>
								<b-form-radio value="whatsapp">Whatsapp</b-form-radio>
							</b-form-radio-group>
						</b-form-group>

						<b-form-select class="mb-3" v-model="models.send_to" :options="masters.sendTo"></b-form-select>
						
						<multiselect 
							v-if="models.send_to !== 'all_user'"
							class="bg-white mb-4 w-100 p-0" 
							v-model="vars.users_id" 
							:options="masters.users"
							:showPointer="false"
							placeholder="Search user" 
							selectLabel=""
							deselectLabel=""
							:hide-selected="true"
							:internal-search="false"
							:loading="vars.isLoading"
							label="name" 
							:max-height="150"
							track-by="value" 
							:multiple="true"
							:closeOnSelect="false"
							@search-change="getUsers"
							>
							<template #noResult>
								<p>No user found. Consider changing the search query</p>
							</template>
						</multiselect>
						
						<b-form-group>
							<b-form-radio-group
								v-model="models.status"
								name="status"
							>
								<b-form-radio value="publish">Publish</b-form-radio>
								<b-form-radio value="draft">Draft</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>
				</b-row>
				<template #footer>
					<b-button type="submit" variant="primary" class="btn-min-width rounded float-left">
						Save
					</b-button>
					<b-button type="button" variant="light" class="btn-min-width rounded float-left" @click="$router.push({name: config.uri})">
						Cancel
					</b-button>
				</template>
			</b-card>
		</div>
	</form>
</template>
<script>
	import api from '@/utils/api.js'
	import ResponseAlert from '@/components/response_alert';
	import Editor from '@/components/editor';

	export default {
		name: 'FormNewsletter',
		components: {
			ResponseAlert,
			Editor
		},
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
				},
				models: {
					title: '',
					description: '',
					send_by: 'email',
					send_to: 'all_user',
					users_id: [],
					status: 'publish',
				},
				editorOption: {
					modules: {
						toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              ['link', 'image'],
              ['clean']
						],
					}
				},
				masters: {
					users: [
					],
					sendTo: [
						{
							value: 'all_user',
							text: 'Send to all'
						},
						{
							value: 'spesific_user',
							text: 'Send to spesific user'
						},
					]
				},
				vars: {
					isLoading: false,
					debounce: null,
					users_id: []
				}
			}
		},
		created() {
			let _ = this
			_.getById();
			_.getUsers()
		},
		methods: {
			getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.$axios.get(`${api.newsletter}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;
							_.models.title = data.title
							_.models.description = data.description
							_.models.send_by = data.send_by
							_.models.send_to = data.send_to
							_.models.status = data.status

							data.users_id.forEach(user => {
								_.vars.users_id.push({
									value: user.id,
									name: user.name,
									email: user.email
								})
							})

						}).catch(() => _.$router.push({
							name: 'page404'
						}));
				}
			},
			getUsers(key = '') {
				clearTimeout(this.debounce)
				this.debounce = setTimeout(() => {
					const _ = this
					_.vars.isLoading = true
					_.$axios.get(api.user, {
						params: {
							status: 'active',
							orderby: 'id',
							sort: 'asc',
							key,
							role_id: '1'
						}
					}).then(res => {
						_.masters.users = []
						res.data.data.rows.forEach(user => {
							_.masters.users.push({
								value: user.id,
								name: `${user.first_name} ${user.last_name}`,
								email: user.email
							})
						})
						_.vars.isLoading = false
					});
				}, 300)

			},
			AddPhoto(event) {
				this.models.image_profile = event.target.files[0];
			},
			save() {
				const _ = this;
				_.$validator.validate().then(valid => {
					if (valid) {
						let request = "";
						_.vars.users_id.forEach(user => {
							_.models.users_id.push({
								id: user.value,
								name: user.name,
								email: user.email
							})
						})

						if(_.models.send_to === 'all_user'){
							_.models.users_id = []
						}
						
						if (!_.$route.params.id) {
							request = _.$axios.post(api.newsletter, _.models);
						} else {
							request = _.$axios.put(`${api.newsletter}/${_.$route.params.id}`, _.models);
						}
						request
							.then(() => _.$router.push({
								name: _.config.uri
							}))
							.catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
					} else {
						window.scrollTo(0, 0);
					}
				});
			}
		}
	}
</script>

<style>
/* #multiselect-container .multiselect__content-wrapper > span  {
	} */
.multiselect, .multiselect__input, .multiselect__single, .multiselect__placeholder {
	font-size: 0.875rem !important;
}
</style>